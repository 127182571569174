import React, { useState, useRef, useEffect, useContext } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import themes from './themes';
import { Layout, LayoutContent, LayoutFooter, LayoutContainer, LayoutColumns, LayoutColumn } from '@paljs/ui/Layout';
import icons from '@paljs/icons';
import { SidebarRefObject } from '@paljs/ui/Sidebar';
import Header from './Header';
import SimpleLayout from './SimpleLayout';
import SidebarCustom from './Sidebar';
import { AuthContext } from '../context/auth';
import { auth, db } from '../utils/fb';
import { doc, updateDoc } from 'firebase/firestore';

const getDefaultTheme = (): DefaultTheme['name'] => {
  if (typeof localStorage !== 'undefined' && localStorage.getItem('theme')) {
    return localStorage.getItem('theme') as DefaultTheme['name'];
  } else {
    const hours = new Date().getHours();
    return hours > 6 && hours < 19 ? 'default' : 'dark';
  }
};

const LayoutPage: React.FC<{ pageContext: { layout: string } }> = ({ children, pageContext }) => {
  const [theme, setTheme] = useState<DefaultTheme['name']>('default');
  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr');
  const sidebarRef = useRef<SidebarRefObject>(null);

  const pathName = typeof window !== 'undefined' && window.location.pathname.startsWith('/app');

  const changeTheme = (newTheme: DefaultTheme['name']) => {
    setTheme(newTheme);
    typeof localStorage !== 'undefined' && localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const localTheme = getDefaultTheme();
    if (localTheme !== theme && theme === 'default') {
      setTheme(localTheme);
    }
  }, []);

  const changeDir = () => {
    const newDir = dir === 'ltr' ? 'rtl' : 'ltr';
    setDir(newDir);
  };

  const { user } = useContext(AuthContext);
  let timer = useRef(null);
  const isBrowser = typeof window !== 'undefined';

  const [timeSpent, setTimeSpent] = useState();

  const calculateLessonTime = () => {
    let timerStart;
    let timeSpentOnSite = getTimeSpentOnSite();

    function getTimeSpentOnSite() {
      timeSpentOnSite = parseInt(localStorage.getItem(`${auth.currentUser?.uid}-timeSpentOnSOSA`));
      timeSpentOnSite = isNaN(timeSpentOnSite) ? 0 : timeSpentOnSite;
      return timeSpentOnSite;
    }

    function startCounting() {
      timerStart = Date.now();
      timer.current = setInterval(function () {
        timeSpentOnSite = getTimeSpentOnSite() + (Date.now() - timerStart);
        localStorage.setItem(`${auth.currentUser?.uid}-timeSpentOnSOSA`, timeSpentOnSite);
        setTimeSpent(timeSpentOnSite);
        timerStart = parseInt(Date.now());
        // Convert to seconds
        // console.log(parseInt(timeSpentOnSite / 1000));
      }, 1000);
    }
    startCounting();
  };

  const updateTimeSpent = async () => {
    await updateDoc(doc(db, 'users', localStorage.getItem('currentUser')), {
      timeSpent,
    });
  };

  useEffect(() => {
    if (isBrowser) {      
      if (user) {        
        localStorage.setItem('currentUser', auth.currentUser?.uid)
        calculateLessonTime();
      } else {
        if (timer.current) {          
          clearInterval(timer.current);
          updateTimeSpent();
        }
      }
    }
  }, [user]);

  return (
    <ThemeProvider theme={themes(theme, dir)}>
      <>
        <SimpleLayout />
        <Layout evaIcons={icons} dir={dir} className={pageContext.layout === 'auth' ? 'auth-layout' : ''}>
          {pageContext.layout !== 'auth' && (
            <Header
              dir={dir}
              changeDir={changeDir}
              theme={{ set: changeTheme, value: theme }}
              toggleSidebar={() => sidebarRef.current?.toggle()}
            />
          )}
          <LayoutContainer>
            {pageContext.layout !== 'auth' && pathName ? <SidebarCustom ref={sidebarRef} /> : null}
            <LayoutContent>
              <LayoutColumns>
                <LayoutColumn className="main-content">{children}</LayoutColumn>
              </LayoutColumns>
              {pageContext.layout !== 'auth' && <LayoutFooter>Footer</LayoutFooter>}
            </LayoutContent>
          </LayoutContainer>
        </Layout>
      </>
    </ThemeProvider>
  );
};

export default LayoutPage;
