import { MenuItemType } from '@paljs/ui/types';

const items: MenuItemType[] = [
  {
    title: 'Users',
    group: true,
  },
  {
  title: 'Start Course',
  link: { to: '/app/instructor/' },
  },
  {
    title: 'Open Courses',
    link: { to: '/app/instructor/courses/open-courses' },
  },
  {
    title: 'Completed Courses',
    link: { to: '/app/instructor/courses/past-courses' },
  }
];

export default items;
