import { MenuItemType } from '@paljs/ui/types';

const items: MenuItemType[] = [
  {
    title: 'Users',
    group: true,
  },
  {
    title: 'All Users',
    link: { to: '/app/users' },
  },
  {
    title: 'Pending Users',
    link: { to: '/app/users/pending-users' },
  },
  {
    title: 'Create New User',
    link: { to: '/app/users/create-user' },
  },
  {
    title: 'Courses',
    group: true,
  },
  {
    title: 'Manage Courses',
    link: { to: '/app/courses' },
  },
  {
    title: 'Create New Course',
    link: { to: '/app/courses/create-course/' },
  }
];

export default items;
