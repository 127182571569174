module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/Layouts/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"oah-admin","short_name":"OAH","start_url":"/dashboard","display":"minimal-ui","icon":"src/images/OAH.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31b663efa53f0c1ef68c5f53f53d55af"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
